import { defineStore } from "pinia";
type SnackType = "primary" | "error" | "info";

export const useSnackbarStore = defineStore("snackbar", () => {
  const { t } = useI18n();

  const show = ref(false);
  const type = ref<SnackType>("info");
  const message = ref("");

  function success(payload = t("success")) {
    show.value = true;
    type.value = "primary";
    message.value = payload;
  }

  function error(payload = t("an-error-happened")) {
    show.value = true;
    type.value = "error";
    message.value = payload;
  }

  function info(payload: string) {
    show.value = true;
    type.value = "info";
    message.value = payload;
  }

  return {
    show,
    type,
    message,
    success,
    error,
    info,
  };
});
